import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: "/login",
  component: () => import('../views/admin/login.vue')
},
{ //后台
  path: '/admin',
  component: () => import('../views/admin/layout.vue'),
  children: [{
    name: "index",
    path: "index",
    component: () => import('../views/admin/index.vue')
  }, {
    name: "stat",
    path: "stat",
    component: () => import('../views/admin/stat.vue')
  }, {
    name: "stat2",
    path: "stat2",
    component: () => import('../views/admin/stat2.vue')
  }, {
    name: "data",
    path: "data",
    component: () => import('../views/admin/data.vue')
  }, {
    name: "warning",
    path: "warning",
    component: () => import('../views/admin/warning.vue')
  }, {
    name: "report",
    path: "report",
    component: () => import('../views/admin/report.vue')
  }, {
    name: "base",
    path: "base",
    component: () => import('../views/admin/base.vue')
  }, {
    name: "powercut",
    path: "powercut",
    component: () => import('../views/admin/powercut.vue')
  }]
},
{ //公司
  path: '/company',
  component: () => import('../views/company/layout.vue'),
  children: [{
    name: "stat",
    path: "stat",
    component: () => import('../views/company/stat.vue')
  }, {
    name: "co2",
    path: "co2",
    component: () => import('../views/company/co2.vue')
  }, {
    name: "board",
    path: "board",
    component: () => import('../views/company/board.vue')
  }, {
    name: "data",
    path: "data",
    component: () => import('../views/company/data.vue')
  }, {
    name: "warning",
    path: "warning",
    component: () => import('../views/company/warning.vue')
  }, {
    name: "report",
    path: "report",
    component: () => import('../views/company/report.vue')
  }, {
    name: "base",
    path: "base",
    component: () => import('../views/company/base.vue')
  },
    //  {
    //   name: "room",
    //   path: "room",
    //   component: () => import('../views/company/room.vue')
    // }, {
    //   name: "room1",
    //   path: "room1",
    //   component: () => import('../views/company/room.vue')
    // }
  ]
},
{
  path: "/*",
  component: () => import('../views/admin/login.vue')
},
]

const router = new VueRouter({
  mode: 'history', //去掉url中的#
  base: process.env.BASE_URL,
  routes
})

export default router